<script>
import { BeneficiosEnum } from "@/core/enums/beneficios";
import { mapGetters } from "vuex";
import _ from "lodash";
import { formatCPF } from "@/helpers/masks";

export default {
  components: {
    "servico-select": () => import("@/components/servico-select.vue"),
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "recalcule-btn": () => import("@/components/recalcule-btn.vue")
  },
  created() {
    this.debouncedLoad = _.debounce(this.doLoad, 100);
  },
  watch: {
    clientId() {
      this.debouncedLoad();
    }
  },
  computed: {
    ...mapGetters(["clientId", "servico", "intervaloCompetencia"]),
    resource() {
      return this.apiResource(
        `/v1/rh/clientes/${this.clientId}/dispendio/projetosAgrupamento`
      );
    },
    resourceRecalculo() {
      return this.apiResource(
        `v1/rh/clientes/${this.clientId}/dispendio/recalrhprojetos`
      );
    },
    cols() {
      return [
        {
          text: "Nome",
          value: "nome"
        },
        {
          text: "CPF",
          value: "cpf",
          format: formatCPF
        },
        {
          text: "Titulação",
          value: "escolaridade"
        },
        {
          text:
            this.servico === BeneficiosEnum.LEI_DO_BEM
              ? "Dedicação"
              : "Tipo RH",
          value: "dedicacao",
          data: "dedicacao"
        },
        {
          text: "Horas",
          value: "horas",
          format: this.$options.filters.toDoubleComplete,
          textAlign: "right"
        },
        {
          text: "Valor",
          value: "valor",
          format: this.$options.filters.toCurrency,
          textAlign: "right"
        }
      ];
    },
    relatorioXlsx() {
      let rows = this.projetos.reduce(
        (prev, { colaboradores, total, projeto }) => {
          let content = (Array.isArray(colaboradores) ? colaboradores : []).map(
            colaborador => {
              const row = this.cols.map(
                ({ value }) => colaborador[value] || ""
              );
              row.unshift(projeto.titulo || "");
              return row;
            }
          );
          let totalRow = this.cols.map(({ value }) => total[value] || "");
          totalRow.splice(1, 0, "");
          return [...prev, ...content, totalRow];
        },
        []
      );

      let styles = rows
        .reduce(
          (prev, row, rowIndex) => {
            const refs = [];

            if ([1, 2].includes(row.length)) {
              refs.push(rowIndex + 2);
            }

            row.forEach((cell, cellIndex) => {
              if (cell === "Total") {
                refs.push(rowIndex + 2);
              }

              if (typeof cell === "string" && cell.includes("R$ ")) {
                rows[rowIndex][cellIndex] = cell.replace("R$ ", "");
              }
            });

            return [...prev, ...refs];
          },
          [1]
        )
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: {
              font: {
                bold: true
              }
            }
          }),
          {}
        );

      const columns = [
        {
          text: "Projeto",
          value: "projeto"
        },
        ...this.cols
      ];

      return [
        rows,
        {
          columns,
          periodoCompetencia: this.intervaloCompetencia,
          servico: this.servico,
          styles
        }
      ];
    },
    totalGeral() {
      return this.projetos.reduce(
        (acc, proj) => {
          acc.horas += proj.total.horas;
          acc.valor += proj.total.valor;
          return acc;
        },
        {
          nome: "Total Geral",
          cpf: null,
          escolaridade: null,
          dedicacao: null,
          horas: 0,
          valor: 0
        }
      );
    }
  },
  methods: {
    doLoad() {
      if (!this.servico) {
        return;
      }

      this.resource
        .get({
          query: `competenciaIni=${this.intervaloCompetencia[0]}&competenciaFim=${this.intervaloCompetencia[1]}&trabalho=${this.servico}`
        })
        .then(response => {
          this.projetos = response.map(row => ({
            ...row,
            total: _.reduce(
              row.colaboradores,
              (rowA, rowB) => {
                rowA.horas += rowB.horas ? rowB.horas * 1 : 0;
                rowA.valor += rowB.valor ? rowB.valor * 1 : 0;
                return rowA;
              },
              { nome: "Total", horas: 0, valor: 0 }
            )
          }));
        });
    },
    recalcularPeriodo() {
      this.resourceRecalculo
        .save({
          trabalho: this.servico,
          competenciaIni: this.competencia[0],
          competenciaFim: this.competencia[1]
        })
        .then(() =>
          this.$notify({
            group: "geral",
            duration: 5000,
            type: "success",
            title: "Recalculo solicitado.",
            text: "Teros irá refazer o cálculo em segundo plano."
          })
        );
    }
  },
  data() {
    return {
      competencia: [],
      debouncedLoad: () => {},
      projetos: []
    };
  }
};
</script>

<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      Resumo Recursos Humanos Por Agrupamento
    </v-card-title>
    <v-card-text class="d-flex flex-row items-center">
      <div class="row pa-2  pl-5 justify-between">
        <div class="row">
          <div class="table-v-action-button mr-3 pt-1" @click="debouncedLoad()">
            <v-icon>mdi-refresh</v-icon>Atualizar
          </div>
          <div
            @click="exportXlsx2(...relatorioXlsx)"
            class="table-v-action-button mr-3 pt-1"
          >
            <v-icon>mdi-file-export-outline</v-icon>Exportação
          </div>
          <monthly-filter
            @change="debouncedLoad()"
            style="min-width: 270px;"
          ></monthly-filter>
          <servico-select
            class="pl-2 mt-0"
            style="max-width: 255px"
            @change="debouncedLoad()"
          ></servico-select>
        </div>
        <div>
          <recalcule-btn
            class="table-v-action-button mr-3 pt-1"
            @change="
              competencia = $event;
              recalcularPeriodo();
            "
          ></recalcule-btn>
        </div>
      </div>
    </v-card-text>
    <template v-if="!projetos.length">
      <span class="font-weight-bold white d-block pl-4 pb-4 pt-6">
        Nenhum registro encontrado para exibição.
      </span>
    </template>
    <template v-for="(proj, idx) in projetos">
      <span
        class="font-weight-bold d-block pl-4 pb-4"
        :class="{ 'pt-6': idx != 0 }"
        :key="idx + 'span'"
      >
        Agrupamento: {{ proj.projeto.titulo }}
      </span>
      <v-data-table
        :key="idx"
        class="tabela-resumo"
        data-app
        :items="proj.colaboradores"
        :headers="cols"
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        hide-default-footer
      >
        <template v-slot:item="{ item, headers }">
          <tr class="table-v-tr" style="cursor: default">
            <td
              v-for="col in headers"
              v-bind:key="col.value"
              :class="`total-geral-${col.value}`"
              :style="{ 'text-align': col.textAlign }"
            >
              {{ col.format ? col.format(item[col.value]) : item[col.value] }}
            </td>
          </tr>
        </template>
        <template v-slot:[`body.append`]="append">
          <tr class="table-v-tr">
            <th
              style="cursor: default; font-size: 13px !important;"
              v-for="col in append.headers"
              v-bind:key="col.value"
              :class="`total-geral-${col.value}`"
              :style="{ 'text-align': col.textAlign }"
            >
              {{
                col.format
                  ? col.format(proj.total[col.value])
                  : proj.total[col.value]
              }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template v-if="projetos.length">
      <div class="total-geral-line" :style="{ backgroundColor: tableColor }">
        <div
          style="cursor: default; font-size: 13px !important;"
          v-for="{ format, textAlign, value } in cols"
          v-bind:key="value"
          :class="`total-geral total-geral-${value}`"
          :style="{ 'text-align': textAlign }"
        >
          {{ format ? format(totalGeral[value]) : totalGeral[value] }}
        </div>
      </div>
    </template>
  </v-card>
</template>

<style lang="scss" scoped>
.tabela-resumo::v-deep thead > tr > th {
  &:nth-last-child(1),
  &:nth-last-child(2) {
    text-align: end !important;
  }
}

.total-geral-line {
  display: flex;
  position: sticky;
  bottom: 0;

  .total-geral-nome {
    text-transform: uppercase;
  }
}

.total-geral {
  display: table-cell;
  height: 3rem;
  padding: 0 1rem;
  font-weight: bold;
  line-height: 4;
  user-select: none;
  white-space: nowrap;
}

.total-geral-nome {
  width: 40%;
}

.total-geral-cpf,
.total-geral-escolaridade,
.total-geral-dedicacao,
.total-geral-horas,
.total-geral-valor {
  width: 12%;
}
</style>
